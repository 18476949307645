:root {
  //#region Size Variables
  --d-10: 1px;
  --d-25: 2px;
  --d-50: 4px;
  --d-75: 6px;
  --d-100: 8px;
  --d-125: 10px;
  --d-150: 12px;
  --d-175: 14px;
  --d-200: 16px;
  --d-225: 18px;
  --d-250: 20px;
  --d-275: 22px;
  --d-300: 24px;

  --d-350: 28px;
  --d-400: 32px;
  --d-450: 36px;
  --d-500: 40px;

  --d-600: 48px;
  --d-700: 56px;
  --d-800: 64px;
  --d-900: 72px;
  --d-1000: 80px;

  --d-1200: 96px;
  --d-1400: 112px;
  --d-1600: 128px;
  --d-1800: 144px;
  --d-2000: 160px;
  --d-2200: 176px;
  --d-2400: 192px;
  --d-2600: 208px;
  --d-2800: 224px;
  --d-3000: 240px;
  --d-3200: 256px;
  --d-3400: 272px;
  --d-3600: 288px;
  --d-3800: 304px;
  --d-4000: 320px;

  --d-5000: 400px;
  --d-6000: 480px;
  --d-7000: 560px;
  --d-8000: 640px;
  --d-9000: 720px;
  --d-10000: 800px;
  // #endregion Size Variables
}
