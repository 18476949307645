// Colors To Expose Globally
// import at the top of your style sheet with the following line
// @use 'relativepathtofile/styles/colors' as *;

// #region Color Variables
:root {
  --text-disabled: #797b80;
  --text-default: #000;

  --primary-50: #dcdee5;
  --primary-100: #b9bdcb;
  --primary-200: #969db1;
  --primary-300: #737c97;
  --primary-400: #505b7d;
  --primary-500: #2e3b63;
  --primary-600: #263152;
  --primary-700: #1e2742;
  --primary-800: #171d31;
  --primary-900: #0f1321;
  --primary-1000: #070910;

  // TODO - Add alpha colors
  --primary-alpha-05: rgba(38, 49, 82, 0.05);
  --primary-alpha-10: rgba(38, 49, 82, 0.1);

  --secondary-50: #fae3dd;
  --secondary-100: #f5c8bb;
  --secondary-200: #f0ad99;
  --secondary-300: #eb9177;
  --secondary-400: #e67655;
  --secondary-500: #e25b34;
  --secondary-600: #bc4b2b;
  --secondary-700: #963c22;
  --secondary-800: #712d1a;
  --secondary-900: #4b1e11;
  --secondary-1000: #250f08;

  --neutral-white: #ffffff;
  --neutral-50: #e8e9e9;
  --neutral-100: #d2d3d4;
  --neutral-200: #bcbdbf;
  --neutral-300: #a5a7aa;
  --neutral-400: #8f9195;
  --neutral-500: #797b80;
  --neutral-600: #64666a;
  --neutral-700: #505255;
  --neutral-800: #3c3d40;
  --neutral-900: #28292a;
  --neutral-1000: #141415;
  --neutral-black: #000000;

  --success-50: #ecf4e4;
  --success-100: #d9e9c9;
  --success-200: #c7dfae;
  --success-300: #b4d493;
  --success-400: #a1c978;
  --success-500: #8fbf5e;
  --success-600: #779f4e;
  --success-700: #5f7f3e;
  --success-800: #475f2f;
  --success-900: #2f3f1f;
  --success-1000: #171f0f;

  --warning-50: #fef3e2;
  --warning-100: #fee8c5;
  --warning-200: #fedca8;
  --warning-300: #fdd18b;
  --warning-400: #fdc56e;
  --warning-500: #fdba51;
  --warning-600: #d29b43;
  --warning-700: #a87c36;
  --warning-800: #7e5d28;
  --warning-900: #543e1b;
  --warning-1000: #2a1f0d;

  --error-50: #fadddd;
  --error-100: #f5bbbb;
  --error-200: #f09999;
  --error-300: #eb7777;
  --error-400: #e65555;
  --error-500: #e23434;
  --error-600: #bc2b2b;
  --error-700: #962222;
  --error-800: #711a1a;
  --error-900: #4b1111;
  --error-1000: #250808;

  --info-50: #d4edef;
  --info-100: #aadce0;
  --info-200: #7fcbd1;
  --info-300: #55b9c1;
  --info-400: #2aa8b2;
  --info-500: #0097a3;
  --info-600: #007d87;
  --info-700: #00646c;
  --info-800: #004b51;
  --info-900: #003236;
  --info-1000: #00191b;

  --alpha-05: 0.05;
  --alpha-10: 0.1;
  --alpha-15: 0.15;
  --alpha-20: 0.2;
  --alpha-25: 0.25;
  --alpha-30: 0.3;
  --alpha-40: 0.4;
  --alpha-50: 0.5;
  --alpha-60: 0.6;
  --alpha-70: 0.7;
  --alpha-80: 0.8;
  --alpha-90: 0.9;
  --alpha-100: 1;

  --primitive-navy-50: #dcdee5;
  --primitive-navy-100: #b9bdcb;
  --primitive-navy-200: #969db1;
  --primitive-navy-300: #737c97;
  --primitive-navy-400: #505b7d;
  --primitive-navy-500: #2e3b63;
  --primitive-navy-600: #263152;
  --primitive-navy-700: #1e2742;
  --primitive-navy-800: #171d31;
  --primitive-navy-900: #0f1321;
  --primitive-navy-1000: #070910;

  --primitive-red-50: #fadddd;
  --primitive-red-100: #f5bbbb;
  --primitive-red-200: #f09999;
  --primitive-red-300: #eb7777;
  --primitive-red-400: #e65555;
  --primitive-red-500: #e23434;
  --primitive-red-600: #bc2b2b;
  --primitive-red-700: #962222;
  --primitive-red-800: #711a1a;
  --primitive-red-900: #4b1111;
  --primitive-red-1000: #250808;

  --primitive-orange-50: #fae3dd;
  --primitive-orange-100: #f5c8bb;
  --primitive-orange-200: #f0ad99;
  --primitive-orange-300: #eb9177;
  --primitive-orange-400: #e67655;
  --primitive-orange-500: #e25b34;
  --primitive-orange-600: #bc4b2b;
  --primitive-orange-700: #963c22;
  --primitive-orange-800: #712d1a;
  --primitive-orange-900: #4b1e11;
  --primitive-orange-1000: #250f08;

  --primitive-yellow-50: #fef3e2;
  --primitive-yellow-100: #fee8c5;
  --primitive-yellow-200: #fedca8;
  --primitive-yellow-300: #fdd18b;
  --primitive-yellow-400: #fdc56e;
  --primitive-yellow-500: #fdba51;
  --primitive-yellow-600: #d29b43;
  --primitive-yellow-700: #a87c36;
  --primitive-yellow-800: #7e5d28;
  --primitive-yellow-900: #543e1b;
  --primitive-yellow-1000: #2a1f0d;

  --primitive-green-50: #ecf4e4;
  --primitive-green-100: #d9e9c9;
  --primitive-green-200: #c7dfae;
  --primitive-green-300: #b4d493;
  --primitive-green-400: #a1c978;
  --primitive-green-500: #8fbf5e;
  --primitive-green-600: #779f4e;
  --primitive-green-700: #5f7f3e;
  --primitive-green-800: #475f2f;
  --primitive-green-900: #2f3f1f;
  --primitive-green-1000: #171f0f;

  --primitive-blue-50: #d4edef;
  --primitive-blue-100: #aadce0;
  --primitive-blue-200: #7fcbd1;
  --primitive-blue-300: #55b9c1;
  --primitive-blue-400: #2aa8b2;
  --primitive-blue-500: #0097a3;
  --primitive-blue-600: #007d87;
  --primitive-blue-700: #00646c;
  --primitive-blue-800: #004b51;
  --primitive-blue-900: #003236;
  --primitive-blue-1000: #00191b;

  --primitive-grayscale-50: #e8e9e9;
  --primitive-grayscale-100: #d2d3d4;
  --primitive-grayscale-200: #bcbdbf;
  --primitive-grayscale-300: #a5a7aa;
  --primitive-grayscale-400: #8f9195;
  --primitive-grayscale-500: #797b80;
  --primitive-grayscale-600: #64666a;
  --primitive-grayscale-700: #505255;
  --primitive-grayscale-800: #3c3d40;
  --primitive-grayscale-900: #28292a;
  --primitive-grayscale-1000: #141415;
}

// #endregion Color Variables

// #region Previous Color Vars
$ks_primary_00: #131c36;
$ks_primary_01: #39497c;
$ks_primary_02: #4d5c91;
$ks_primary_03: #626fa6;
$ks_primary_04: #7784bc;

$ks_accent1_00: #005965;
$ks_accent1_01: #00828e;
$ks_accent1_02: #0097a3;
$ks_accent1_03: #22c3cf;
$ks_accent1_04: #64f0fc;

$ks_accent2_00b: #3A6A09B2;
$ks_accent2_00: #3a6a09;
$ks_accent2_01: #649336;
$ks_accent2_02: #8fbf5e;
$ks_accent2_03: #bbec88;
$ks_accent2_04: #bbec88;

$ks_accent3_00: #c2731c;
$ks_accent3_01: #f39d47;
$ks_accent3_02: #fdba51;
$ks_accent3_03: #ffd75c;
$ks_accent3_04: #ffee72;

$ks_secondary_00b: #AF2F0DB2;
$ks_secondary_00: #af2f0d;
$ks_secondary_01: #e25b34;
$ks_secondary_02: #ff875b;
$ks_secondary_03: #ff9d6f;
$ks_secondary_04: #ffca99;

$white1: #ffffff;
$white2: #f3f3f3;
$white3: #f2f2f2;
$grey1: rgba(255, 255, 255, 0.5);
$grey2: #dadde5;
$blueblack: #0a0f1c;
$labelgrey: #6f7690;
$labellghtgrey: #2e3b63 60%;
$black: #000000;

// #endregion
